import { x } from '@xstyled/styled-components';
import { PropsWithChildren } from 'react';

import { Organization } from '../../../../generated/types-and-hooks';
import { Icon } from '../../common/components/Icon';
import { LoadingSpinner } from '../../common/components/LoadingSpinner';
import { Text } from '../../common/components/Text';
import { OrganizationAvatar } from './OrganizationAvatar';

export const OrganizationButtonWrapper: React.FC<
  PropsWithChildren<{
    onClick?: () => void;
  }>
> = ({ onClick, children }) => {
  return (
    <x.div
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      border="default"
      borderRadius="md-lg"
      borderColor="gray.200"
      cursor="pointer"
      px={5}
      py={4}
      spaceX={4}
      onClick={onClick}
    >
      {children}
    </x.div>
  );
};

export const OrganizationButton: React.FC<
  PropsWithChildren<{
    organization: Pick<Organization, 'id' | 'shortId' | 'logoId' | 'name'>;
    onClick?: () => void;
    loading?: boolean;
  }>
> = ({ organization, onClick, loading, children }) => {
  const { name } = organization;

  return (
    <OrganizationButtonWrapper onClick={onClick}>
      <x.div>
        {loading ? (
          <x.div
            display="flex"
            alignItems="center"
            justifyContent="center"
            w={9}
            h={9}
          >
            <LoadingSpinner size="4" />
          </x.div>
        ) : (
          <OrganizationAvatar size="m" organization={organization} />
        )}
      </x.div>
      <x.div w="full">
        <Text variant="lg-semibold">{name}</Text>
        {children}
      </x.div>
      <Icon size="6" name="chevron-right" color="gray.300" />
    </OrganizationButtonWrapper>
  );
};
