import { Language } from '@frond/shared/lib/i18n';
import { NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';

import { OrganizationsView } from '../../modules/organizations/OrganizationsView';

export async function getStaticProps({ locale }: { locale: Language }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}

const OrganizationsPage: NextPage = () => {
  return <OrganizationsView />;
};

export default OrganizationsPage;
