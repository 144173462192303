import { routes } from '@frond/shared';
import { x } from '@xstyled/styled-components';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { useViewer } from '../auth/hooks/useViewer';
import { FrondLogo } from '../common/components/FrondLogo';
import { FullscreenPageLayout } from '../common/components/FullscreenPageLayout';
import { Heading } from '../common/components/Heading';
import { Icon } from '../common/components/Icon';
import { NextLink } from '../common/components/NextLink';
import { Text } from '../common/components/Text';
import { useSignOut } from '../common/hooks/useApollo';
import { OrganizationButtonWrapper } from './components/OrganizationButton';
import { OrganizationInviteButton } from './components/OrganizationInviteButton';
import { OrganizationMembershipButton } from './components/OrganizationMembershipButton';

/**
 * Full screen component used for organizations page
 */
export const OrganizationsView: React.FC = () => {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const signout = useSignOut();

  return (
    <FullscreenPageLayout>
      <x.div display="flex" minH="100vh" alignItems="center">
        <x.div
          minWidth={{
            sm: 'lg',
          }}
          w={{
            _: '100%',
            sm: 'auto',
          }}
          mx="auto"
          my={10}
          boxSizing="border-box"
          py={10}
          px={6}
          flexDirection="column"
          borderRadius="lg"
          boxShadow={{ _: 'none', 'sm-2': '2px 2px 30px rgba(0, 0, 0, 0.18);' }}
        >
          <x.div
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="stretch"
            spaceY={10}
          >
            <x.div w={20}>
              <FrondLogo />
            </x.div>
            <x.div display="flex" flexDirection="column" alignItems="center">
              <Heading variant="xl" mb={2}>
                {t('communities.greeting')}
              </Heading>
              <Text variant="md">
                {t('communities.for_user', { userEmail: viewer?.email })}
              </Text>
              {viewer?.organizationMemberships.length === 0 ? (
                <Text color="gray.300" maxW="xs" textAlign="center">
                  You don&apos;t have any communities. Create a new one below!
                </Text>
              ) : null}
            </x.div>
            <x.div w="full" spaceY={2} data-test="groups-membership-button">
              {viewer?.organizationMemberships.map(
                (organizationMembership, i) => (
                  <OrganizationMembershipButton
                    key={i}
                    organizationMembership={organizationMembership}
                  />
                )
              )}

              {viewer?.userInvites.map((userInvite, i) => (
                <OrganizationInviteButton key={i} userInvite={userInvite} />
              ))}
              <x.div color="gray.500">
                <NextLink
                  href={routes.groups.organizationsNew()}
                  display="block"
                  styleInheritColor
                >
                  <OrganizationButtonWrapper>
                    <Icon size="5" name="plus" />
                    <x.div w="full">
                      <Text variant="md-semibold">
                        {t('communities.create')}
                      </Text>
                    </x.div>
                  </OrganizationButtonWrapper>
                </NextLink>
              </x.div>
              <x.div pt={2} textAlign="center">
                <x.button
                  onClick={() => signout()}
                  backgroundColor="transparent"
                  outline="none"
                  border="none"
                  cursor="pointer"
                >
                  <Text color="gray.300" variant="sm">
                    {t('communities.sign_out')}
                  </Text>
                </x.button>
              </x.div>
            </x.div>
          </x.div>
        </x.div>
      </x.div>
    </FullscreenPageLayout>
  );
};
