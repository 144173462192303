import { useTranslation } from 'next-i18next';

import { ViewerQuery } from '../../../../generated/types-and-hooks';
import { useOptionalOrganization } from '../../auth/hooks/useOptionalOrganization';
import { Text } from '../../common/components/Text';
import { OrganizationButton } from './OrganizationButton';

export const OrganizationMembershipButton: React.FC<{
  organizationMembership: NonNullable<
    ViewerQuery['viewer']['organizationMemberships']
  >[0];
}> = ({ organizationMembership }) => {
  const { t } = useTranslation();
  const { setAndViewOrganization } = useOptionalOrganization();
  const { organization } = organizationMembership;
  const { numMembers } = organization;

  return (
    <OrganizationButton
      organization={organization}
      onClick={() => setAndViewOrganization(organization)}
    >
      <Text variant="sm" color="gray.300">
        {numMembers} {numMembers === 1 ? t('member') : t('members')}
      </Text>
    </OrganizationButton>
  );
};
