import { ViewerQuery } from '../../../../generated/types-and-hooks';
import { useViewer } from '../../auth/hooks/useViewer';
import { Text } from '../../common/components/Text';
import { useJoinOrganization } from '../../invites/hooks/useJoinOrganization';
import { OrganizationButton } from './OrganizationButton';

export const OrganizationInviteButton: React.FC<{
  userInvite: NonNullable<ViewerQuery['viewer']['userInvites']>[0];
}> = ({ userInvite }) => {
  const { organization } = userInvite;
  const { id: organizationId } = organization;
  const { viewer } = useViewer();
  const { mutateJoinOrganization, loading } = useJoinOrganization({
    organization,
  });

  const handleClick = () => {
    if (viewer) {
      mutateJoinOrganization({
        variables: {
          input: {
            userId: viewer.id,
            organizationId,
            inviteCode: userInvite.inviteCode,
          },
        },
      });
    }
  };

  return (
    <OrganizationButton
      organization={organization}
      onClick={handleClick}
      loading={loading}
    >
      <Text variant="sm" color="green.300">
        You&apos;ve been invited
      </Text>
    </OrganizationButton>
  );
};
